<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>下游协议</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="sub-merchants-info">
            <span>下游协议</span>
            <div class="flex">
            <div class="sub-merchants-container">
                <div class="input-item">
                    <div class="input-item-title">{{ $getTitleByType('会议名称', pid.cid) }}</div>
                    <el-select
                        ref="pName"
                        v-model="pName"
                        filterable
                        placeholder="请选择会议名称"
                        @change="proChange">
                        <el-option
                            v-for="(item, index) in projectOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="text-red self-end margin-bottom-sm" v-if="bubijia">该会议无需提供三方比价,原因为：{{bubijia}}</div>
                <div class="input-item">
                    <div class="input-item-title">所属学会</div>
                    <el-select
                        v-model="society"
                        :placeholder="`请选择所属学会（请选择${$getTitleByType('会议名称',pid.cid)}）`"
                        @visible-change="clickProject">
                        <el-option
                            v-for="item in societyOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{ $getTitleByType('会议日期', pid.cid) }}</div>
                    <el-input
                        v-model="pid.time"
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                        disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{ $getTitleByType('会议', pid.cid) }}形式</div>
                    <el-input v-model="pid.meet_form_str" :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`" disabled></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{ $getTitleByType('大会主席', pid.cid) }}</div>
                    <el-input
                        v-model="pid.chairman"
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                        disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">付款类型</div>
                    <el-radio-group v-model="payment_type">
                        <el-radio :label="1">{{ $getTitleByType('会议基金', pid.cid) }}</el-radio>
                        <el-radio :label="2">专项基金</el-radio>
                    </el-radio-group>
                </div>
                <div class="input-item">
                    <div class="input-item-title">委托项目</div>
                    <el-input v-model="payment" placeholder="请输入委托项目"></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">是否需要比价</div>
                    <el-radio-group v-model="is_price_comparison" @change="is_price_comparisonChange">
                        <el-radio :label="1">需要</el-radio>
                        <el-radio :label="0">不需要</el-radio>
                    </el-radio-group>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{ $getTitleByType('供应商名称', pid.cid) }}（全称）</div>
                    <el-select
                        ref="corporate_name"
                        v-model="corporate_name"
                        filterable
                        :placeholder="`请选择${$getTitleByType('供应商名称', pid.cid) }（全称）`"
                        @change="corporateChange">
                        <el-option
                            v-for="(item, index) in priceComparisons"
                            :key="index"
                            :label="`${item.enterprise_name}${item.is_overdue ? '(营业执照已过期)' : ''}`"
                            :disabled="item.is_overdue"
                            :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">供应商备注</div>
                    <el-input v-model="corporate_name_remarks" placeholder="请输入供应商备注"></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">联系人</div>
                    <el-autocomplete
                        v-model="contacts"
                        :fetch-suggestions="querySearchUser"
                        :trigger-on-focus="false"
                        class="inline-input"
                        placeholder="请输入联系人"
                        value-key="contacts"
                    ></el-autocomplete>
                </div>
                <div class="input-item">
                    <div class="input-item-title">合同金额</div>
                    <el-input
                        v-model="amounts"
                        placeholder="请输入合同金额"
                        type="number"
                        @input="setAmount(0)"
                        @mousewheel.native.prevent
                    ></el-input>
                    <div class="money">万元</div>
                    <div :class="{red:if_update}" class="money-count">
                        合同可签金额<span>{{ payment_type === 1 ? amount_meet : amount_self }}</span
                    >万元
                    </div>
                </div>
                <div v-if="parseFloat(Number(amounts).toFixed(6))>parseFloat(Number(payment_type === 1 ? amount_meet : amount_self ).toFixed(6))" class="tip-red">
                    合同金额超过合同可签金额
                </div>
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">会议金额</div>-->
                <!--<el-input-->
                <!--v-model="meeting_decimal"-->
                <!--placeholder="请输入会议金额"-->
                <!--type="number"-->
                <!--@mousewheel.native.prevent-->
                <!--@input="setAmount(2)"-->
                <!--&gt;</el-input>-->
                <!--<div class="money">万元</div>-->
                <!--</div>-->
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">自用金额</div>-->
                <!--<el-input-->
                <!--v-model="self_amount"-->
                <!--placeholder="请输入自用金额"-->
                <!--type="number"-->
                <!--@mousewheel.native.prevent-->
                <!--@input="setAmount(1)"-->
                <!--&gt;</el-input>-->
                <!--<div class="money">万元</div>-->
                <!--</div>-->
                <div class="input-item">
                    <div class="input-item-title">加盖何种印章</div>
                    <el-select
                        v-model="seal_id"
                        disabled
                        placeholder="请选择加盖何种印章"
                    >
                        <el-option
                            v-for="item in sealOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div class="input-item">
                    <div class="input-item-desc">备注</div>
                    <el-input
                        v-model="remark"
                        :rows="5"
                        placeholder="请输入"
                        resize="none"
                        type="textarea"
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-desc">上传附件</div>
                    <div>
                        <div v-if="files.length > 0" class="upfile-list">
                            <ul v-for="(file, index) in files" :key="index">
                                <li class="flex-center between-box">
                                    <a
                                        :href="file.filePath | filePath"
                                        class="ovHide"
                                        target="_blank"
                                    >{{ file.fileName }}</a
                                    >
                                    <i class="el-icon-delete" @click="closeFile(index)"></i>
                                </li>
                            </ul>
                        </div>
                        <el-upload
                            :action="uploadPath"
                            :before-upload="beforeAvatarUpload"
                            :data="QiniuData"
                            :multiple="true"
                            :on-error="uploadError"
                            :on-success="uploadSuccess"
                            :show-file-list="false"
                        >
                            <el-button size="small" type="primary">点击上传文件</el-button>
                            <!--<div slot="tip" class="el-upload__tip">只能上传PDF文件</div>-->
                        </el-upload>
                    </div>
                </div>
                <div class="input-item">
                    <div class="input-item-title">合同状态</div>
                    <el-radio-group v-model="contract">
                        <el-radio :label="1">已回</el-radio>
                        <el-radio :label="0">未回</el-radio>
                    </el-radio-group>
                    <el-date-picker
                        v-if="contract == 1"
                        v-model="contract_time"
                        placeholder="请输入合同已回时间"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </div>
                <approvalList
                    :copyMembers="copyMembers"
                    :isNoHave="!id"
                    :typeClassify="6"
                    @setApproval="setApproval"
                    @setMember="setMember"
                ></approvalList>
                <el-button :disabled="requestState" :loading="requestState" type="primary" @click="subApproval">提交
                </el-button>
            </div>
                <div class="info-list flex flex-direction padding-sm margin-top-sm margin-left flex-sub" v-if="priceComparisons.length && is_price_comparison">
                    <span>供应商比价</span>
                    <div class="info-item flex flex-direction" v-for="(item,index) in priceComparisons" :key="index">
                        <div class="name">委托项目：{{item.payment}}</div>
                        <div class="name margin-top-xs">供应商：{{item.enterprise_name}}<i class="text-red" v-if="item.is_overdue">(营业执照已过期)</i></div>
                        <div class="remark margin-top-xs">备注：{{item.audit_opinion}}</div>
                        <div class="file margin-top-xs">
                            <a v-for="(file, index) in item.files" :key="index" :href="file.filePath | filePath"
                               target="_blank">{{ file.fileName }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import approvalList from "../../components/approvalList.vue";
import {genUpToken, qiniuaddr, uploadPath} from "../../tools/CryptoJS";
import {webUrl} from "../../tools";

export default {
    components: {
        ElButton,
        ElInput,
        approvalList,
    },
    name: "contract",
    data() {
        return {
            id: "",
            sign_code: "",

            society: "",
            societyOptions: [],
            pid: "",
            pName: "",
            bubijia: "",
            projectOptions: [],
            priceComparisons: [],
            corporate_name: "",
            corporate_name_remarks: "",
            enterprise_id: "",
            contacts: "",
            amounts: "",
            amount_meet: "0",
            amount_self: "0",
            if_update: false,
            self_amount: "",
            meeting_decimal: "",
            payment_type: 1,
            is_price_comparison: 1,
            contract: 0,
            contract_time: "",
            payment: "",

            remark: "",
            seal_id: 2,
            sealOptions: this.$tools.sealTypes(),
            shenpi: "",
            copy: "",
            QiniuData: {
                fname: "", //  文件名
                key: "", //文件名字处理
                token: "", //七牛云token
            },
            qiniuaddr: qiniuaddr, // 七牛云的图片外链地址 你的七牛云里配置有
            uploadPath: uploadPath, // 七牛云的图片外链地址 你的七牛云里配置有
            files: [],
            users: [],
            approvalMembers: [],
            copyMembers: [],
            requestState: false
        };
    },
    created() {
        let token = genUpToken();
        this.QiniuData.token = token;
        this.id = this.$route.query.id ? this.$route.query.id : '';
        this.contract_time = this.$tools.ZHDate(new Date(), 6)
        this.$emit("setIndex", [4, 12]);
        this.init();
    },
    methods: {
        setAmount(type) {
            if (type === 0) {
                this.amounts = this.amounts.replace(/[^0-9 -.]/g, "");
                this.self_amount = "0";
                this.meeting_decimal = "0";
            } else if (type === 1) {
                this.self_amount = this.self_amount.replace(/[^0-9 -.]/g, "");
                if (this.amounts) {
                    if (this.self_amount) {
                        this.meeting_decimal = parseFloat((
                            Number(this.amounts).toFixed(6) - Number(this.self_amount).toFixed(6)).toFixed(6));
                    } else {
                        this.meeting_decimal = parseFloat(Number(this.amounts).toFixed(6));
                    }
                } else {
                    if (this.self_amount && this.meeting_decimal) {
                        this.amounts = parseFloat((
                            Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                    }
                }
            } else {
                this.meeting_decimal = this.meeting_decimal.replace(/[^0-9 -.]/g, "");
                if (this.amounts) {
                    if (this.meeting_decimal) {
                        this.self_amount = parseFloat((
                            Number(this.amounts).toFixed(6) - Number(this.meeting_decimal).toFixed(6)).toFixed(6));
                    } else {
                        this.self_amount = parseFloat(Number(this.amounts).toFixed(6));
                    }
                } else {
                    if (this.self_amount && this.meeting_decimal) {
                        this.amounts = parseFloat((
                            Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                    }
                }
            }
        },
        querySearchUser(queryString, cb) {
            const restaurants = this.users;
            const results = queryString
                ? restaurants.filter(this.createUserFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createUserFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.contacts
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) !== -1
                );
            };
        },
        subApproval() {
            if (!this.pid) {
                this.$message.error(this.$refs.pName.placeholder);
                return;
            }

            if (!this.society) {
                this.$message.error("请选择所属学会");
                return;
            }

            if (!this.payment) {
                this.$message.error("请输入委托项目");
                return;
            }
            if (!this.corporate_name) {
                this.$message.error(this.$refs.corporate_name.placeholder);
                return;
            }
            if (!this.contacts) {
                this.$message.error("请输入联系人");
                return;
            }
            if (!this.amounts) {
                this.$message.error("请输入合同金额");
                return;
            }
            if (this.self_amount === "") {
                this.$message.error("请输入专项金额");
                return;
            }
            if (this.meeting_decimal === "") {
                this.$message.error("请输入会议金额");
                return;
            }
            if (!this.seal_id) {
                this.$message.error("请选择加盖何种印章");
                return;
            }
            //                if (!this.remark) {
            //                    this.$message.error("请输入备注")
            //                    return
            //                }
            if (this.files.length <= 0) {
                this.$message.error("请上传附件");
                return;
            }
            if (this.contract === 1 && !this.contract_time) {
                this.$message.error("请输入合同已回时间");
                return;
            }
            if (this.shenpi.indexOf("-1") !== -1) {
                this.$message.error("请完善审批人员");
                return;
            }
            this.requestState = true
            //添加
            this.$api.apiContent
                .approvalHt({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sid: this.society,
                    pid: this.pid.id,
                    sign_code: this.sign_code,
                    corporate_name: this.corporate_name,
                    corporate_name_remarks: this.corporate_name_remarks,
                    enterprise_id: this.enterprise_id,
                    contacts: this.contacts,
                    contract_time: this.contract === 1 ? this.contract_time : "",
                    payment: this.payment,
                    payment_type: this.payment_type,
                    amount: this.amounts,
                    self_amount: this.self_amount,
                    meeting_decimal: this.meeting_decimal,
                    contract: this.contract,
                    remark: this.remark,
                    files: JSON.stringify(this.files),
                    is_price_comparison:this.is_price_comparison,
                    shenpi: this.shenpi,
                    copy: this.copy,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            sid: this.society,
                            pid: this.pid.id,
                            sign_code: this.sign_code,
                            corporate_name: this.corporate_name,
                            corporate_name_remarks: this.corporate_name_remarks,
                            enterprise_id: this.enterprise_id,
                            contacts: this.contacts,
                            contract_time: this.contract === 1 ? this.contract_time : "",
                            payment: this.payment,
                            payment_type: this.payment_type,
                            amount: this.amounts,
                            self_amount: this.self_amount,
                            meeting_decimal: this.meeting_decimal,
                            contract: this.contract,
                            remark: this.remark,
                            files: JSON.stringify(this.files),
                            is_price_comparison:this.is_price_comparison,
                            shenpi: this.shenpi,
                            copy: this.copy,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.requestState = false
                    this.$message.success(res.msg);
                    this.$router.push("send");
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        proChange(index) {
            let value = this.projectOptions[index];
            value.time =
                this.$tools.ZHDate(new Date(value.start_time * 1000)) +
                " 至 " +
                this.$tools.ZHDate(new Date(value.end_time * 1000));
            this.pName = value.name;
            this.bubijia = value.bubijia;
            this.pid = value;
            this.society = ''
            this.amount_meet = '0'
            this.amount_self = '0'
            this.if_update = false
            this.corporate_name= ''
            this.enterprise_id= ''
            this.priceComparisons=[]
            this.getSociety();
            this.getPriceComparisonQuery();
        },
        clickProject(view) {
            if (!this.pid && view) {
                this.$message.error("请选择会议名称");
            }
        },
        corporateChange(index){
            let value = this.priceComparisons[index];
            if (!value.is_overdue || value.businessLicense_end_time === '0000-00-00'){
                this.corporate_name = value.enterprise_name;
                this.enterprise_id = value.enterprise_id;
                if (value.is_blacklist){
                    this.$confirm("该供应商在黑名单里", "温馨提示", {
                        confirmButtonText: "确定",
                        showCancelButton:false,
                        type: "warning",
                    })
                }
            }else {
                this.corporate_name = ''
                this.enterprise_id = ''
            }

        },
        getPriceComparisonQuery() {
            this.$api.apiContent
                .getPriceComparisonQuery({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid: this.pid.id,
                    is_price_comparison:this.is_price_comparison,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.pid.id,
                            is_price_comparison:this.is_price_comparison,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.priceComparisons = res.data;
                })
                .catch(() => {
                    this.priceComparisons = [];
                });
        },
        getSociety() {
            this.$api.apiContent
                .getSocietyFromPro({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid: this.pid.id,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.pid.id,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.societyOptions = res.data;
                    if (res.data.length === 1 && !this.society) this.society = res.data[0].id;
                })
                .catch(() => {
                    this.societyOptions = [];
                });
        },
        getAmountXie() {
            this.$api.apiContent
                .getAmountXie({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid: this.pid.id,
                    sid: this.society,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.pid.id,
                            sid: this.society,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.amount_meet = res.data.amount_meet
                    this.amount_self = res.data.amount_self
                    this.if_update = res.data.if_update === 1
                })
                .catch(() => {
                    this.amount_meet = "0"
                    this.amount_self = "0"
                    this.if_update = false
                });
        },
        //  页面加载时请求
        init() {
            this.$api.apiContent
                .getProject({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.map(item => {
                        item.name = this.$tools.ZHDate(new Date(item.start_time * 1000)) + " 至 "
                            + this.$tools.ZHDate(new Date(item.end_time * 1000)) + '/' + item.chairman + '/' + item.name
                    })
                    this.projectOptions = res.data;
                })
                .catch(() => {
                    this.projectOptions = [];
                });
            this.$api.apiContent
                .getUserList({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.users = res.data;
                })
                .catch(() => {
                    this.users = [];
                });
            if (this.id) {
                this.$api.apiContent
                    .getHetong({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
//            this.approvalMembers = res.data.shenpi;
//            this.shenpi = res.data.shenpi
//              .map((item) => {
//                return item.user_id;
//              })
//              .join(",");
                        this.copyMembers = res.data.copy;
                        this.copy = res.data.copy
                            .map((item) => {
                                return item.user_id;
                            })
                            .join(",");
                        this.society = res.data.sid;
                        this.sign_code = res.data.sign_code;
                        res.data.project.time =
                            this.$tools.ZHDate(
                                new Date(res.data.project.start_time * 1000)) +
                            " 至 " +
                            this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                        res.data.project.id = res.data.pid
                        res.data.project.meet_form_str = res.data.meet_form_str
                        this.pid = res.data.project;
                        this.pName = this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) + " 至 "
                            + this.$tools.ZHDate(new Date(res.data.project.end_time * 1000)) + '/' + res.data.project.chairman + '/' + res.data.project.name;
                        this.name = res.data.name;
                        this.corporate_name = res.data.corporate_name;
                        this.corporate_name_remarks = res.data.corporate_name_remarks;
                        this.enterprise_id = res.data.enterprise_id;
                        this.payment = res.data.payment;
                        this.contacts = res.data.contacts;
                        this.contract_time = res.data.contract_time;
                        this.payment_type = res.data.payment_type;
                        this.amounts = parseFloat(Number(res.data.amount).toFixed(6));
                        this.self_amount = res.data.self_amount;
                        this.meeting_decimal = res.data.meeting_decimal;
                        this.contract = res.data.contract;
                        this.remark = res.data.remark;
                        this.files = res.data.files;
                        this.is_price_comparison = res.data.is_price_comparison
                        this.getSociety();
                        this.getPriceComparisonQuery();
                    })
                    .catch(() => {
                    });
            }
        },
        //  上传成功
        uploadSuccess(response, file, fileList) {
            console.log(file);
            console.log(fileList);
            this.files.push({
                filePath: `${this.qiniuaddr}/${response.key}`,
                //在这里你就可以获取到上传到七牛的外链URL了
                fileName: file.name,
            });

            this.loading.close();
        },
        closeFile(position) {
            this.$confirm("此操作将删除该文件, 是否继续?", "温馨提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(() => {
                    this.files.splice(position, 1);
                })
                .catch();
        },
        //  上传失败
        uploadError() {
            this.loading.close();
            this.$message({
                message: "上传出错，请重试！",
                type: "error",
                center: true,
            });
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'application/pdf';
            // if (!isJPG) {
            //   this.$message.error('只能上传PDF格式!');
            //   return
            // }
            //这里的key给加上了时间戳，目的是为了防止上传冲突
            this.QiniuData.fname = file.name;
            this.QiniuData.key = `${new Date().getTime()}${file.name}`;
            this.loading = this.$loading({
                lock: true,
                text: "上传中，请稍后",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },
        backPage() {
            this.$tools.goBack();
        },
        //审批人
        setApproval(members) {
            this.shenpi = members;
        },
        //抄送人
        setMember(members) {
            this.copy = members;
        },
        is_price_comparisonChange() {
            this.corporate_name = ''
            this.enterprise_id = ''
            this.getPriceComparisonQuery()
        }
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
    },
    watch: {
        society() {
            if (this.society) this.getAmountXie();
        },
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .info-list{
        border: 1px solid #e1e1e1;
        max-height: 800px;
        overflow-x: auto;
        .info-item{
            border: 1px solid #e1e1e1;
            margin-top: 20px;
            padding: 15px;
            .name{
                font-size: 18px;
                color: #333333;
            }
            .remark{
                font-size: 16px;
                color: #666666;
            }
            .file {
                display: flex;
                flex-direction: column;
                a {
                    font-size: 18px;
                    cursor: pointer;
                    margin-bottom: 8px;
                    display: block;
                    color: #3b77e7;

                    &:hover {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .sub-merchants-info {
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        padding: 40px 25px;
        min-height: 800px;

        span {
            color: #000000;
            font-size: 24px;
        }

        .sub-merchants-container {
            width: 60%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .tip-red {
                color: red;
                font-weight: bold;
                margin-left: 23%;
                margin-bottom: 20px;
            }

            .input-item {
                display: flex;
                min-height: 40px;
                margin-bottom: 20px;
                flex-direction: row;
                align-items: center;
                overflow: hidden;

                .upfile-list {
                    width: 400px;

                    li {
                        height: 31px;
                        border-bottom: 1px dashed #ccc;

                        a {
                            display: block;
                            width: 350px;
                            height: 30px;
                            line-height: 30px;
                            color: #3b77e7;
                        }

                        .el-icon-delete {
                            font-size: 16px;
                            cursor: pointer;

                            &:hover {
                                color: #3b77e7;
                            }
                        }
                    }
                }

                .money {
                    flex-shrink: 0;
                    margin-left: 10px;
                }

                .money-count {
                    flex-shrink: 0;
                    margin-left: 10px;
                    font-size: 16px;

                    &.red {
                        color: red !important;

                        span {
                            color: red !important;
                        }
                    }
                }

                .input-item-title {
                    width: 20%;
                    text-align: center;
                    font-size: 16px;
                    margin-right: 20px;
                    color: #000000;
                    flex-shrink: 0;
                }

                .input-item-desc {
                    width: 20%;
                    align-self: flex-start;
                    margin-top: 10px;
                    margin-right: 20px;
                    text-align: center;
                    font-size: 16px;
                    color: #000000;
                    flex-shrink: 0;
                }

                .el-input,
                .el-autocomplete,
                .el-select,
                .el-textarea,
                .el-radio-group,
                .el-date-editor,
                .el-upload {
                    flex-grow: 1;
                    resize: none;
                }
            }

            > .el-button {
                margin-top: 30px;
                align-self: center;
                width: 40%;
            }
        }
    }
}
</style>
